














import { loadFile } from "@/helpers/useLoader";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseAvatar extends Vue {
    @Prop({ default: "button" }) color!: string;
    @Prop({ default: 40 }) size!: string | number;
    @Prop() user!: any;

    public defaultPhoto = require("@/assets/default_profile.jpg");

    get photoPath() {
        if (!this.user || !this.user.profile) return null;

        return this.user.profile.photo_path || null;
    }

    get initialName() {
        if (!this.user || !this.user.profile) return null;

        let initialName = this.user.profile.display_name;

        if (!this.user.profile) initialName = this.user.username;

        return initialName.charAt(0);
    }

    loadFile(path: string) {
        return loadFile(path);
    }
}
