




















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseLink extends Vue {
    @Prop() to!: string;
    @Prop() newWindow!: boolean;
    @Prop() disabled!: boolean;

    openWindow() {
        console.log(this.to);

        if (!this.disabled) window.open(this.to);
    }
}
