







































































































import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import { toDate } from "@/helpers/Formatter";
import { loadFile } from "@/helpers/useLoader";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
        BaseAvatar,
        BaseLink,
    },
})
export default class SharedProposalListItem extends Vue {
    @Prop() proposal!: any;
    @Prop() index!: number;

    public isClickable: boolean = true;

    updateClickable() {
        this.isClickable = false;
    }

    toDate(date: any) {
        return toDate(date);
    }

    loadFile(path: string) {
        return loadFile(path);
    }

    @Watch("isClickable", { immediate: false })
    resetIsClickable(isClickable: boolean) {
        if (!isClickable)
            setTimeout(() => {
                this.isClickable = true;
            }, 500);
    }

    mounted() {
        // console.log(this.proposal.created_at);
    }
}
