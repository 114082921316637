var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.proposal)?_c('div',[_c('div',{class:_vm.isClickable ? '' : 'events-none'},[_c('base-card',[_c('base-link',{attrs:{"to":'/app/proposal/' + _vm.proposal.id},on:{"click":_vm.updateClickable}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.index > -1 && _vm.proposal && _vm.proposal.cover_path)?_c('v-sheet',{class:hover ? 'cursor-pointer' : '',attrs:{"max-height":"280","color":"grey lighten-2"}},[(_vm.proposal.cover_path)?_c('v-img',{class:_vm.$isMobile ? '' : 'rounded-t-lg',attrs:{"src":_vm.loadFile(_vm.proposal.cover_path),"max-height":"280"}}):_vm._e()],1):_vm._e()]}}],null,false,251263137)})],1),_c('v-layout',{staticClass:"pa-3",attrs:{"column":""}},[_c('v-layout',{staticClass:"align-start"},[_c('base-avatar',{attrs:{"size":"32","user":_vm.proposal.user}}),_c('v-layout',{staticClass:"ml-3",attrs:{"column":""}},[_c('base-link',{attrs:{"to":'/app/pengusul/' + _vm.proposal.user.id},on:{"click":_vm.updateClickable}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('p',{staticClass:"mb-0 mt-n1 font-weight-medium",class:hover
                                            ? 'text-decoration-underline text--primary cursor-pointer'
                                            : 'text--secondary',domProps:{"textContent":_vm._s(
                                        _vm.proposal.user.profile.display_name
                                    )}})]}}],null,false,4292089287)})],1),_c('p',{staticClass:"mb-0 mt-n1 caption text--disabled",domProps:{"textContent":_vm._s(_vm.toDate(_vm.proposal.created_at))}}),_c('base-link',{attrs:{"to":'/app/proposal/' + _vm.proposal.id},on:{"click":_vm.updateClickable}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var hover = ref.hover;
return [_c('p',{staticClass:"mb-3 title",class:hover
                                            ? 'primary--text cursor-pointer'
                                            : '',domProps:{"textContent":_vm._s(_vm.proposal.title)}})]}}],null,false,669253479)})],1),_c('base-link',{attrs:{"to":'/app/proposal?kategori=' +
                                _vm.proposal.category.name},on:{"click":_vm.updateClickable}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var hover = ref.hover;
return [_c('p',{staticClass:"mb-3 body-2",class:hover
                                            ? 'text--primary cursor-pointer'
                                            : 'text--secondary',domProps:{"textContent":_vm._s('#' + _vm.proposal.category.name)}})]}}],null,false,3283006046)})],1),_c('v-layout',{staticClass:"align-center"},[_c('v-icon',{staticClass:"mr-2",domProps:{"textContent":_vm._s('mdi-eye')}}),_c('span',{staticClass:"body-2 font-weight-light",domProps:{"textContent":_vm._s(_vm.proposal.seens.length || 0)}})],1)],1)],1)],1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }